import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const iframeRef = useRef(null);

  const [initialMessage, setinitialMessage] = useState();

  //const [message, setmessage] = useState();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': '7e3R9-c9Y4T-6WQ1-4Jd8-89beS-g4Y2I'
  }

  const cloudheaders = {
    'CF-Access-Client-Id': 'ae738388eaa3739fd17745f9bb9b4a5b.access',
    'CF-Access-Client-Secret': '1e12fb6993a750d1473ff9207bd78e9899b4cf32d478e871f01c60495a343169'
  }

  function getAllURLParameters(url) {
    if (!url) {
      url = window.location.href;
    }
    var params = {};
    var queryStart = url.indexOf("?") + 1;
    var queryEnd = url.indexOf("#") !== -1 ? url.indexOf("#") : url.length;
    var query = url.slice(queryStart, queryEnd);

    if (query === '') {
      return params;
    }

    var queryPairs = query.split("&");

    for (var i = 0; i < queryPairs.length; i++) {
      var pair = queryPairs[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1] || "");

      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].push(value);
        } else {
          params[key] = [params[key], value];
        }
      } else {
        params[key] = value;
      }
    }
    return params;
  }
  var urlParams = getAllURLParameters();

  const baseURL = "https://audit-app.poorvika.com/";
  //const baseURL = "https://stage-audit-app.poorvika.com/";
  //const baseURL = "http://localhost:3000/";
  const templateID = "646ca5419a58e6485c501841";

  useEffect(() => {
    const handleMessage = async (e) => {
      const { type, values } = e.data;
      if (type === "initial") {
        //console.log(urlParams);
        setinitialMessage(values);
      } else {
        //console.log(urlParams);
        let value = values;
        if (values !== undefined) {
          value.notify = (values && values.notify) ? 'Y' : 'N';
          //value.ticketId = urlParams.ticketId ?? '';
          //value.status = urlParams.status ?? '';
          //value.currentStatus = urlParams.currentStatus ?? '';
          console.log(values);
        }
        //setmessage(value);

        const pathname = window.location.href;

        value &&
          await axios.post(`https://bcrm.poorvika.com/api/v1/enquires/customer-feedback?ticketId=${urlParams.ticketId ?? ''}&status=${urlParams.status ?? ''}&currentStatus=${urlParams.currentStatus ?? ''}`, value, {
            headers: headers
          })
            .then((response) => {
              response = JSON.parse(JSON.stringify(response.data));

              if (response.errorType)
                //alert(response["errorMessage"]);
                simpleToast(response["errorMessage"])
              else {
                //alert(response["message"]);
                console.log("response : " + response);
                simpleToast(response["message"]);

                setTimeout(function () {
                  if (!pathname.includes("mobile"))
                    window.location.replace('https://poorvika.com/');
                  else {
                    var iframe = document.getElementById("test-frame");
                    iframe.src = iframe.src;
                  }
                }, 2500);
              }

              //alert("JSON send Successfully");
            }).catch((error) => {
              console.error("error : " + error);
              simpleToast("Something Went Wrong. Try again.");
            });
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const sendMessage = () => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        type: "initial",
        values: initialMessage,
      },
      baseURL
    );
  };

  // eslint-disable-next-line
  useEffect(() => {
    setTimeout(() => {
      sendMessage();
    }, 1000);
    // eslint-disable-next-line
  }, [initialMessage]);

  function simpleToast(data) {
    var x = document.getElementById("simpleToast");
    x.innerHTML = data;
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  return (
    <div>
      <iframe
        title="demo"
        className="iframe"
        ref={iframeRef}
        id="test-frame"
        width={"100%"}
        headers={cloudheaders}
        src={`${baseURL}dynamic_template/${templateID}?align=center`}
      ></iframe>
      <div id="simpleToast">
        <span></span>
      </div>
    </div>
  );
}

export default App;
